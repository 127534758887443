/*
 * FILTERS
 */

.c-filter-nav {

    a {
        text-transform: uppercase;
        display: inline-block;
        font-family: 'Ubuntu', Helvetica, sans-serif;;
        padding: .625em 1.125em;
        font-weight: 500;
        transition: background-color .2s ease-out;
        border-radius: 10px;

        &:hover, &.isActive {
            color: $white;
            background-color: $secondary;
        }
    }
}