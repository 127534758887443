/*
 * CONTACT
 */

.c-contact-map {
    height: 40vh;
    background-color: $light;
}

.c-contact-form {

}
