/*
 * REALISATIONS
 */

.c-realisations-list {
    display: block;
    background-color: $light;
}

.c-realisations-teaser, .c-realisations-list {

    .o-col {
        font-size: 1rem;
        line-height: 1;
        padding: .5rem .5rem;
    }
}

.c-realisation-item {
    position: relative;
    z-index: 1;
    max-width: 400px;

    a {
        display: block;
    }

    figure {
        display: inline-block;
        position: relative;
        float: left;
        overflow: hidden;
        cursor: pointer;
        margin: 0;
        padding: 0;
        backface-visibility: hidden;
    }

    img {
        position: relative;
        display: block;
        opacity: 1;
        width: 100%;
        backface-visibility: hidden;
        transition: transform 0.35s;
        transform: scale(1.15);
    }

    figcaption {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;

        &:after {
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: $primary;
            opacity: .4;
            z-index: 0;
        }
    }

    .figcaption-content {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        opacity: 1;
    }

    h3 {
        color: white;
        text-align: center;
    }

    p {
        color: white;
        text-align: center;
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
    }

    @include media-query(md) {

        max-width: none;

        figcaption {

            &:after {
                opacity: 0;
                transition: opacity 0.35s;
            }
        }

        .figcaption-content {
            opacity: 0;
            transition: opacity 0.35s;
        }

        &:hover {

            img {
                transform: translate3d(0,0,0) scale(1);
            }

            figcaption {

                &:after {
                    opacity: .5;
                }
            }

            .figcaption-content {
                opacity: 1;
            }
        }
    }
}