/*
 * FOOTER
 */

.c-footer {
    background-color: $primary;

    a.c-nav-isActive {
        opacity: 1;
    }

    .c-icon {
        fill: $secondary;
    }

    .c-form-input-text {
        background-color: darken($primary, 5%);
        border: 1px solid transparent;
        color: $white;

        input {
        }

        &:focus {
            border: 1px solid $secondary;
        }
    }
}

.c-footer-copyright {
    background-color: darken($primary, 5%);
}

.c-footer, c-footer-copyright {

    h6, p, address, a, p a {
        color: $white;
        opacity: .85;
        font-style: normal;
    }

    a, address, p a{
        transition: opacity .2s ease-out;
        font-style: normal;
        text-decoration: none;

        &:hover {
            opacity: 1;
        }
    }
}