/*
 * BUTTONS
 */

.c-button {
    text-transform: uppercase;
    text-decoration: none !important;
}

.c-button-underline {
    display: inline-block;
    transition: all .1s ease;
    font-weight: bold;
    color: $dark;
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        color: $dark;
    }
}

.c-button-primary {

    &:hover {
        background-color: lighten($primary, 5%);
    }
}

.c-button-secondary {

    &:hover {
        background-color: lighten($secondary, 5%);
    }
}

.c-button-underline-primary {
    color: $primary;

    &:hover {
        color: lighten($primary, 5%);
    }
}

.c-button-underline-secondary {
    color: $secondary;

    &:hover {
        color: lighten($secondary, 5%);
    }
}