/*
 * INTRO
 */

.c-intro {

}

.c-image-container {
    position: relative;
    max-width: 320px;

    &:after {
        position: absolute;
        content: "";
        display: block;
        width: 90%;
        height: 90%;
        background-color: $secondary;
        margin-right: -15px;
        right: 0;
        bottom: 0;
        z-index: 0;
        opacity: .5;
    }

    img {
        position: relative;
        z-index: 1;
    }
}