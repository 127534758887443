/*
 * CARDS
 */

.c-card-grid {

    > .o-col {
        margin-bottom: $gutter;
    }
}

.c-card {
    display: block;
    background: $white;
    box-shadow: 0 4px 15px rgba(black, .1);

    @include media-query(sm) {
        max-width: none;
    }
}

a.c-card {
    transition: all .2s ease;

    &:hover {
        box-shadow: 0 12px 36px rgba(black, .15);
    }
}