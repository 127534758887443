/*
 * SLIDER
 */

.c-slider {
    z-index: 0;
    display: block;
    width: 100%;
    background-color: $white;
    overflow: hidden;

    @include media-query(md) {
        height: 50vh;
    }
}

.c-slider-slick {

    margin-bottom: 0 !important;

    @include media-query(md) {
        max-height: 50vh;
    }

    .slick-slide {

        @include media-query(md) {
            max-height: 50vh;
        }

        display: flex;
        align-items:center;
        cursor: pointer;

        div{
            .home & {
                width:100%;
            }
        }
    }

    .slick-dots {

        bottom: 16px;
    }
}

.slick-dots {

    bottom: -50px;

    li button {

        &:before {
            color: $grey;
            opacity: 1;
            font-size: 8px;
        }
    }

    li.slick-active button {

        &:before {
            color: $secondary;
            opacity: 1;
        }
    }
}

.c-slider-quotes {
    background-color: $primary;
    position: relative;

    .o-container {
        position: relative;
    }

    .c-slider-left, .c-slider-right {
        cursor: pointer;
        background-color: transparent;
        position: absolute;
        top: 50%;
        opacity: .85;
        transition: opacity .2s ease-out;

        .c-icon {
            fill: $white;
        }

        &:hover {
            opacity: 1;
        }

        &:focus {
            border: none;
            outline: none;
        }
    }

    .c-slider-left {
        left: 0;
    }

    .c-slider-right {
        right: 0;
    }
}

.c-slider-slick-quotes {

    .slick-slide {
        color: $white;
        cursor: pointer;

        img {
            border-radius: 50%;
        }
    }
}

.c-slider-left, .c-slider-right {
    cursor: pointer;
    background-color: transparent;
    opacity: .85;
    transition: opacity .2s ease-out;

    .c-icon {
        fill: $white;
    }

    &:hover {
        opacity: 1;
    }

    &:focus {
        border: none;
        outline: none;
    }
}

.c-slider-slick-content-controls-counter {
    font-size: 1rem;
}

.c-image-gallery {

    .o-container {
        position: relative;
    }

    .slick-slide {

    }

    img{
        max-height:800px;
        width:auto;
        margin: 0 auto;
    }

    .c-slider-left, .c-slider-right {
        cursor: pointer;
        background-color: transparent;
        position: absolute;
        top: 50%;
        opacity: .85;
        transition: opacity .2s ease-out;

        .c-icon {
            fill: $primary;
        }

        &:hover {
            opacity: 1;
        }

        &:focus {
            border: none;
            outline: none;
        }
    }

    .c-slider-left {
        left: 0;
    }

    .c-slider-right {
        right: 0;
    }

}