/*
 * SETTINGS
 */

// GRID
$columns: 12;
$gutter: 16/16*1rem;

$breakpoints: (
    xs: 480/16*1rem,
    sm: 640/16*1rem,
    md: 960/16*1rem,
    lg: 1024/16*1rem,
    xl: 1140/16*1rem
);

// SPACING
$spacing: (
    auto: auto,
    none: 0/16*1rem,
    xs: 8/16*1rem,
    sm: 16/16*1rem,
    md: 32/16*1rem,
    lg: 40/16*1rem,
    xl: 48/16*1rem,
    xxl: 80/16*1rem
);

// COLORS
$info: #000000;
$success: #4caf50;
$warning: #ffc107;
$error: #e70638;

$dark: #202020;
$medium: #5a5a5a;
$grey: #979797;
$light: #f8f8f8;        // rgba(238, 238, 238, 1)
$white: #ffffff;

$primary: #1E4573;      // rgba(30, 69, 115, 1)
$secondary: #FFAD00;    // rgba(198, 3, 127, 1)
$tertiary: #237dc0;     // rgba(35, 125, 192, 1)

// BORDER RADIUS
$border-radius: 2px;

// TYPOGRAPHY

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Ubuntu:400,500');

$base-font-family: 'Open Sans', Helvetica, sans-serif;
$base-font-size-min: .75rem;
$base-font-size-max: .85rem;
$base-font-weight: normal;
$base-line-height: 1.5;
$base-color: $dark;

$h1-font-family: 'Ubuntu', Helvetica, sans-serif;
$h1-font-size-min: 1.65rem;
$h1-font-size-max: 1.85rem;
$h1-font-weight: bold;
$h1-line-height: 1.4;
$h1-color: $primary;

$h2-font-family: 'Ubuntu', Helvetica, sans-serif;
$h2-font-size-min: 1.45rem;
$h2-font-size-max: 1.55rem;
$h2-font-weight: 500;
$h2-line-height: 1.4;
$h2-color: $primary;

$h3-font-family: 'Ubuntu', Helvetica, sans-serif;
$h3-font-size-min: 1.25rem;
$h3-font-size-max: 1.45rem;
$h3-font-weight: 400;
$h3-line-height: 1.4;
$h3-color: $dark;

$h4-font-family: 'Ubuntu', Helvetica, sans-serif;
$h4-font-size-min: 1.15rem;
$h4-font-size-max: 1.375rem;
$h4-font-weight: 400;
$h4-line-height: 1.4;
$h4-color: $dark;

$h5-font-family: 'Ubuntu', Helvetica, sans-serif;
$h5-font-size-min: 1.15rem;
$h5-font-size-max: 1.375rem;
$h5-font-weight: 400;
$h5-line-height: 1.4;
$h5-color: $dark;

$h6-font-family: 'Ubuntu', Helvetica, sans-serif;
$h6-font-size-min: 1.15rem;
$h6-font-size-max: 1.4rem;
$h6-font-weight: 400;
$h6-line-height: 1.375;
$h6-color: $dark;