/*
 * HEADER
 */

.c-header {
    position: relative;
    width: 100%;
    box-shadow: 0 4px 15px rgba(black, .1);
    z-index: 1;

    &:after {
        display: none;

        @include media-query(md) {
            display: block;
            content: "";
            position: absolute;
            width: 150px;
            height: 150px;
            border-radius: 50%;
            bottom: -1em;
            margin-top: -1rem;
            left: 50%;
            margin-left: -75px;
            box-shadow: 0 4px 15px rgba(black, .1);
        }
    }
}

.c-header-logo {

    .c-header-logo-link {

        img {
            height: 55px;
        }
    }

    @include media-query(md) {
        position: relative;
        overflow: visible;
        display: block;

        .c-header-logo-link {
            position: absolute;
            background-color: $white;
            border-radius: 50%;
            width: 150px;
            height: 150px;
            margin-top: -75px;
            margin-left: -75px;

            img {
                display: block;
                max-height: none;
                max-width: none;
                height: 85px;
                margin-top: -15px;
            }
        }
    }
}

.c-header-top {
    background-color: $primary;

    a {
        color: $white;
        opacity: .85;
        transition: all.2s ease-out;

        &:hover {
            opacity: 1;
            color: $secondary;
        }
    }

    .c-icon {
        fill: $secondary;
    }
}

.c-header-top-left {

    @include media-query(md) {
        width: 50%;
        padding-right: 100px;
    }

    a {
        @include first() {
            padding-left: 0;
        }
    }
}

.c-header-top-right {

    @include media-query(md) {
        width: 50%;
        padding-left: 100px;
    }

    a {
        @include last() {
            padding-right: 0;
        }
    }
}

.c-header-bottom {
    position: relative;
    background-color: $white;
    z-index: 2;

    a {
        text-transform: uppercase;
        font-family: 'Ubuntu', Helvetica, sans-serif;;
        font-weight: 500;
        transition: all .2s ease-out;

        &:hover {
            color: $primary;
            opacity: 1;
        }

        @include interpolate(
            font-size,
            map-get($breakpoints, xs),
            map-get($breakpoints, xl),
            .75rem,
            .95rem
        );
    }
}

.c-header-bottom-left {
    width: 50%;
    padding-right: 75px;

    @include media-query(lg) {
        padding-right: 100px;
    }

    a {
        opacity: .65;

        @include first() {
            padding-left: 0;
        }
    }
}

.c-header-bottom-right {
    width: 50%;
    padding-left: 75px;

    @include media-query(lg) {
        padding-left: 100px;
    }

    a {
        opacity: .65;

        @include last() {
            padding-right: 0;
        }
    }
}

.c-nav-isActive {
    color: $primary;
    opacity: 1 !important;
}