/*
 * NEWSLETTER
 */

.c-newsletter {
    background-color: $primary;
    position: relative;

    .o-container {
        position: relative;
    }

    h3 {
        color: $white;
    }

    .FormBuilder{
        flex: 3;
    }

    .Inputfields{
        display: flex;
    }

    .c-form-group{
        display: flex;
        flex-grow:1;
        align-items: center;
        .c-label{
            white-space: nowrap;
            margin-right: 1em;
        }
    }

    .c-button{
        margin-left:1em;
        padding: .8em 1.125em;
    }

    .error, .success{
        color: $white;
    }
}