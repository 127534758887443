/*
 * VENDORS
 */

//@import 'vendor/jqueryui/jqueryui';

@import 'vendor/processwire/_processwire.cookies';

@import 'vendor/royalslider/_rs-royalslider';
@import 'vendor/royalslider/_rs-default';
@import 'vendor/royalslider/_rs-default-inverted';
@import 'vendor/royalslider/_rs-minimal-white';
@import 'vendor/royalslider/_rs-universal';

@import 'vendor/slick/_slick';
@import 'vendor/slick/_slick-theme';

@import "vendor/fontawesome/fontawesome.scss";
@import "vendor/fontawesome/brands.scss";
@import "vendor/fontawesome/regular.scss";
@import "vendor/fontawesome/solid.scss";
