/*
 * SEARCH
 */

.c-search-list {
    display: block;
    background-color: $light;

    a {
        font-size: 1.15em;
        font-weight: bold;
        text-decoration: underline;
        display: block;
        color: $primary;
        transition: box-shadow 0.35s;

    }
}