/*
 * PROMOTIONS
 */

.c-promotions-intro {

}

.c-promotions {

}

.c-promotions-nav {

}

.c-promotions-list {
    display: block;
    background-color: $light;
}

.grey-bg {
    background-color: $light;
    width: 100%;
}

.regular-price {
    text-decoration: line-through;
}

.promo-price {
    font-weight: bold;
    color: $secondary;
}