/*
 * CARDS
 */

.c-card {
    position: relative;
    max-width: 400px;
    transition: box-shadow 0.35s;

    h4 {
        color: $primary;
    }

    hr {
        background-color: $grey;
        opacity: .5;
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        text-indent: 200%;
        white-space: nowrap;
        font-size: 0;
        opacity: 0;
    }

    &:hover {
        box-shadow: 0 8px 30px rgba(black, .15);
    }
}

.c-card-promo {
    position: absolute;
    background-color: $secondary;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    left: 15px;
    top: 15px;

    p {
        color: $white;
        font-weight: bold;
        font-size: 1rem;
    }
}