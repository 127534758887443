/*
 * WORKMETHOD
 */

.c-workmethod {
    background-color: $primary;
    position: relative;

    h2, h3, h4, h5, p {
        color: $white;
    }

    .o-container {
        position: relative;
    }

}