// SOCIAL BUTTONS

ul.MarkupSocialShareButtons {

    list-style-type: none;

    li {
        display: inline-block;
    }

    .email {
        @extend %fa-icon;
        @extend .fas;
        color: $primary;
        transition: color .25s;
        font-size: 1.1rem;
        margin-right: 8px;

        &:before {
            content: fa-content($fa-var-envelope);
        }

        &:hover {
            color: $secondary;
        }
    }

    .linkedin, .facebook, .twitter, .instagram {

        @extend %fa-icon;
        @extend .fab;
        color: $primary;
        transition: color .25s;
        font-size: 1.1rem;
        margin-right: 8px;

        &:before {
            padding-bottom: 4px;
        }

        &:hover {
            color: $secondary;
        }
    }

    .linkedin {
        &:before {
            content: fa-content($fa-var-linkedin);
        }
    }

    .facebook {
        &:before {
            content: fa-content($fa-var-facebook-square);
        }
    }

    .twitter {
        &:before {
            content: fa-content($fa-var-twitter-square);
        }
    }

    .instagram {
        &:before {
            content: fa-content($fa-var-instagram);
        }
    }
}
